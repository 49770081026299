import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

export const ErrorMessage = ({
  error,
  className,
  name,
  max,
  min,
  num,
}: {
  error?: string;
  name?: string;
  className?: string;
  max?: string | number;
  min?: string | number;
  num?: string | number;
}) => {
  const { t } = useTranslation();
  return (
    <div className="font-Poppins min-h-4">
      {error
        ? error && (
            <p
              className={`
                caption flex flex-row items-center align-middle text-[13px] font-normal text-[#960912] ${className}`}
            >
              <ExclamationCircleIcon className="mr-1 mt-[2px] h-3 w-3 shrink-0" />
              {t(error, {
                name: t(name ?? ''),
                max: max ?? '',
                min: min ?? '',
                num: num ?? '',
              })}
            </p>
          )
        : ''}
    </div>
  );
};
