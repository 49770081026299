import type { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../ErrorMessage';
import type { LabelProps } from '../Label';
import { Label } from '../Label';

type RadioInput = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'label' | 'id' | 'ref'
>;

interface RadioProps extends RadioInput {
  label?: string;
  id?: string;
  gap?: string;
  radioSize?: string;
  radioLabelClassName?: string;
  setCurrentId?: (id: string) => void;
  field?: any;
  wrapperClassName?: string;
  radioButtonClassName?: string;
}

const RadioItem = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      id = 'radio',
      label,
      radioSize,
      gap = 'space-x-2',
      disabled,
      setCurrentId,
      field,
      wrapperClassName,
      radioLabelClassName,
      radioButtonClassName,
      value,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <span
        className={`inline-flex items-center ${gap} peer ${wrapperClassName}`}
      >
        <span className="relative flex items-center ">
          <input
            ref={ref}
            id={id}
            type="radio"
            className="peer absolute left-1/2 top-1/2 -z-10 h-0 w-0 opacity-0"
            {...field}
            {...rest}
            value={value}
          />

          <button
            value={value}
            onClick={() => {
              const radio = document.getElementById(id);
              if (radio && !disabled) {
                radio.click();
                setCurrentId && setCurrentId(id);
              }
            }}
            className={`border-secondary-75 peer-disabled:border-secondary-50 inline-flex h-4 w-4 peer-disabled:cursor-not-allowed peer-disabled:bg-white ${
              disabled
                ? 'peer-checked:bg-secondary-100'
                : 'peer-checked:bg-primary-500'
            } peer-checked:border-secondary-200 shrink-0 cursor-pointer items-center justify-center rounded-full border bg-white ${radioSize} ${radioButtonClassName}`}
            type="button"
          >
            <span className="h-2 w-2 rounded-full bg-white" />
          </button>
        </span>
        {label ? (
          <label
            htmlFor={disabled ? 'disabled' : id}
            className={`peer-checked:text-primary cursor-pointer text-sm
              ${radioLabelClassName}
              ${disabled ? 'text-secondary-100 cursor-not-allowed' : ''}
`}
          >
            {t(label)}
          </label>
        ) : null}
      </span>
    );
  }
);
RadioItem.displayName = 'Radio';

export interface RadioGroupProps extends LabelProps {
  children?: ReactNode;
  className?: string;
  error?: string;
  disableErrorPlaceholder?: boolean;
  childrenClassName?: string;
  name?: string;
  requiredTag?: boolean;
}
const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      children,
      name,
      childrenClassName,
      className,
      label,
      labelBadge,
      labelBadgeClass,
      labelId,
      labelPostText,
      labelSubText,
      required,
      error,
      labelNote,
      disableErrorPlaceholder,
      labelBarRequired,
      labelClassName,
      requiredTag,
      ...rest
    },
    ref
  ) => {
    let errorName = '';
    if (name?.length) {
      errorName = name;
    } else if (typeof label === 'string') {
      errorName = label;
    }
    return (
      <>
        <div ref={ref} {...rest} className={className}>
          {label ? (
            <Label
              labelClassName={labelClassName}
              labelNote={labelNote}
              label={label}
              labelBadge={labelBadge}
              labelBadgeClass={labelBadgeClass}
              labelId={labelId}
              labelPostText={labelPostText}
              labelSubText={labelSubText}
              required={required}
              labelBarRequired={labelBarRequired}
              requiredTag={requiredTag}
            />
          ) : null}
          {childrenClassName ? (
            <div className={childrenClassName}>{children}</div>
          ) : (
            children
          )}
        </div>
        {!disableErrorPlaceholder && (
          <ErrorMessage error={error} name={errorName} />
        )}
      </>
    );
  }
);
RadioGroup.displayName = 'RadioGroup';

export { RadioGroup, RadioItem };
