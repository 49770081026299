/* eslint-disable @next/next/no-img-element */
import { Text } from '@chakra-ui/react';
import { i18n } from 'next-i18next';
import { formatDateInWords } from 'lib';

type Props = {
  src: string;
  link: string;
  title: string;
  publishDate: string;
};

export const Article = (props: Props) => {
  const { src, link, title, publishDate } = props;

  return (
    <a href={link} target={'_blank'} rel="noopener noreferrer">
      <div className="group flex cursor-pointer flex-col transition duration-200  ease-in sm:max-w-full">
        <img
          src={src}
          className="group-hover:z-120 m-auto !min-h-[155.63px] w-full min-w-[280px] transform
             border border-solid border-gray-500 object-cover object-center transition  duration-200 ease-in  group-hover:z-50 group-hover:opacity-80 sm:min-w-[297.63px] sm:group-hover:scale-105 "
          alt="article"
        />
        <Text className="text-gray-secondary mt-[8px] text-base">
          {formatDateInWords(publishDate, i18n?.language)}
        </Text>
        <Text className="mt-[8px] line-clamp-2 text-lg font-semibold decoration-solid group-hover:underline">
          {title}
        </Text>
      </div>
    </a>
  );
};
