import { format } from 'date-fns';

export const truncateTextWithLength = (
  text: string,
  characterCount: number
): string => {
  if (!text) return '';
  return text.length > characterCount
    ? `${text.substring(0, characterCount)}...`
    : text;
};

export const formatDateInWords = (date: string, locale?: string) => {
  const formatStr = locale === 'en' ? 'yyyy MMM' : 'yyyy年 M月';

  try {
    return format(new Date(date), formatStr);
  } catch (error) {
    console.error('Invalid date format:', error);
    return date;
  }
};
