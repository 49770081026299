import React, { ReactNode, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { InputGroup, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { ErrorMessage } from '..';

type FileUploadProps = {
  register: UseFormRegisterReturn;
  accept?: string;
  multiple?: boolean;
  icon?: React.ReactNode;
  label?: string;
  required?: boolean;
  errorMessage?: string;
  children?: ReactNode;
  id?: string;
  labelClassName?: string;
  onFileChange?: (files: FileList | null) => void;
};

export const Upload = (props: FileUploadProps) => {
  const {
    register,
    accept,
    multiple,
    children,
    icon,
    label,
    required,
    errorMessage,
    id,
    labelClassName,
    onFileChange,
  } = props;
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register as {
    // eslint-disable-next-line no-unused-vars
    ref: (_instance: HTMLInputElement | null) => void;
  };
  const handleClick = () => inputRef.current?.click();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (onFileChange) {
      onFileChange(files);
    }
  };

  return (
    <div className="min-h-[7.5rem]">
      <div className="flex items-center">
        {icon}
        <Text
          as="label"
          className={`
           ${icon && 'ml-[10px]'}
        font-Poppins text-gray-primary inline text-[18px] font-normal leading-[26px] ${labelClassName}`}
        >
          {label}
        </Text>

        <Text
          as="span"
          className={`font-Poppins text-gray-secondary pl-[3px] text-[18px] font-normal leading-[26px] ${labelClassName}`}
        >
          {required ? t('(Required)') : t('(Optional)')}
        </Text>
      </div>
      <InputGroup onClick={handleClick}>
        <input
          id={id}
          type={'file'}
          multiple={multiple || false}
          hidden
          accept={accept}
          {...rest}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
          onChange={handleFileChange}
        />
        <>{children}</>
      </InputGroup>
      {errorMessage && <ErrorMessage error={errorMessage} />}
    </div>
  );
};
