import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

export interface LabelProps {
  label?: ReactNode;
  required?: boolean;
  labelSubText?: string;
  labelPostText?: string;
  labelId?: string;
  labelBadge?: string;
  labelBadgeClass?: string;
  labelNote?: ReactNode;
  labelBarRequired?: boolean;
  labelClassName?: string;
  requiredTag?: boolean;
}

export const Label = ({
  label,
  required,
  labelSubText,
  labelPostText,
  labelId,
  labelBadge,
  labelBarRequired,
  labelBadgeClass,
  labelNote,
  labelClassName,
  requiredTag,
}: LabelProps) => {
  const { t } = useTranslation();
  return (
    <>
      {label ? (
        <>
          <label
            htmlFor={labelId}
            className={`
              form-label font-Poppins flex items-center gap-1 ${labelClassName}`}
          >
            {required ? (
              <>
                {labelBarRequired ? (
                  <span className=" bg-primary mr-2 h-4 w-0.5 shrink-0 font-semibold">
                    <span className="sr-only">必須</span>
                  </span>
                ) : (
                  <span className="text-error-500 -ml-1 font-semibold">*</span>
                )}
              </>
            ) : null}
            {typeof label === 'string' ? (
              <span id={labelId}>{t(label)}</span>
            ) : (
              label
            )}
            {labelSubText ? (
              <span className="text-sm font-normal text-gray-500">
                {labelSubText}
              </span>
            ) : null}
            {labelPostText ?? null}
            {requiredTag ? (
              <p className="ml-2 shrink-0 text-xs font-normal text-[#FF4D4F]">
                {t('required')}
              </p>
            ) : null}
          </label>
          {labelNote ? <p className="text-secondary-200">{labelNote}</p> : null}
        </>
      ) : null}
    </>
  );
};
