/* eslint-disable @next/next/no-img-element */
import { Text } from '@chakra-ui/react';
import { formatDateInWords } from 'lib';
import { i18n } from 'next-i18next';
import Link from 'next/link';
import LinkIcon from 'public/news/link.svg';

type Props = {
  id: string;
  img: string;
  link: string;
  title: string;
  date: string;
  label: string;
  slug: string;
};

export const NewsCard = (props: Props) => {
  const { img, title, date, label, slug } = props;

  return (
    <Link href={`/news/${slug}`} passHref>
      <a rel="noopener noreferrer">
        <div className="group flex cursor-pointer flex-col transition duration-200  ease-in sm:max-w-full">
          <div className="container relative">
            <div className="absolute right-4 top-4 z-10 hidden group-hover:block">
              <LinkIcon />
            </div>
            <img
              src={
                img
                  ? `${process.env.NEXT_PUBLIC_STATIC_URL}/news/${img}`
                  : `${process.env.NEXT_PUBLIC_STATIC_URL}/news/fallback.png`
              }
              className="group-hover:z-120 m-auto !min-h-[155.63px] w-full min-w-[280px] transform
             border border-solid border-gray-500 object-cover object-center transition  duration-200 ease-in  group-hover:z-50 group-hover:opacity-80 sm:min-w-[297.63px] sm:group-hover:scale-105 "
              alt="article"
            />
            <div className="textblock absolute bottom-4 left-2 bg-white p-2 ">
              <Text className="text-black-secondary text-base font-semibold">
                {label}
              </Text>
            </div>
          </div>
          <Text className="text-gray-secondary mt-[8px] text-base">
            {formatDateInWords(date, i18n?.language)}
          </Text>
          <Text className="mt-[8px] line-clamp-2 text-lg font-semibold decoration-solid group-hover:underline">
            {title}
          </Text>
        </div>
      </a>
    </Link>
  );
};
