import { Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { typography, Button } from 'ui';
import { WorkCard } from '../../molecules';
import { useTranslation } from 'next-i18next';
import { WORKS_BG, WORKS_SLIDERS } from '../../../constants/works';

const OurWorks = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(0 as number);

  return (
    <div className="relative h-[994px] w-full md:h-[768px]">
      <Carousel
        interval={7000}
        selectedItem={active}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        animationHandler="fade"
        onChange={(index: number) => setActive(index)}
      >
        {WORKS_BG.map((video: string, index: number) => (
          <div className="relative" key={index}>
            <video
              playsInline
              muted
              height={768}
              className="h-[994px] object-cover md:h-[768px]"
              width={'100%'}
              data-testid="video-asset"
              role="video"
              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/our-works/${video}`}
              controls={false}
              autoPlay={true}
              loop={true}
            />
          </div>
        ))}
      </Carousel>

      <div className="bg-primary-900 absolute inset-0 flex bg-opacity-60">
        <div className="3xl:justify-start m-auto ml-6 mr-6 flex w-full max-w-[1440px] justify-end lg:ml-[60px] lg:mr-0">
          <div className="w-full lg:pr-[30px] xl:pr-16 ">
            <div className="border-l-[6px] border-solid pl-4 text-white">
              <Text as="h2" className="font-Rubik mb-4" {...typography?.h4}>
                {t('Our Work')}
              </Text>
              <Text className="font-Poppins" {...typography?.medium}>
                {t(
                  'We have experience in business co-creation and DX support for to C and to B with many startups, municipalities, and companies.'
                )}
              </Text>
            </div>

            <div className="border-primary-300 font-Poppins mt-8 grid w-full grid-cols-1 gap-[26px] border-b border-r border-none border-opacity-50 text-white md:grid-cols-2 md:gap-0 md:border-solid  lg:grid-cols-3">
              {WORKS_SLIDERS.map((work, index) => (
                <WorkCard
                  onClick={(selected: number) => setActive(selected)}
                  isActive={active === index}
                  work={work}
                  index={index}
                  key={index}
                />
              ))}
            </div>

            <div className="w-full">
              <Button
                btnVarient="link"
                href="/contact"
                type="secondary"
                className="mt-8 !w-full !border-white !text-white md:!w-fit"
              >
                {t('Get more information')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurWorks;
