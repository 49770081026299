import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import { Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { typography, Button } from 'ui';
import { Article } from 'components/molecules';

const TechBlog = forwardRef(
  ({ blogs, id }: any, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <section
        id={id}
        ref={ref}
        className="mb-[60px] mt-[60px] pr-0 lg:pr-[30px] xl:pr-[60px]"
      >
        <div className="border-l-[6px] border-[#3767B1] md:pl-[16px]">
          <Text
            {...typography?.fontPoppins}
            className="pl-[16px]  text-2xl font-bold md:border-l-0 md:border-none md:pl-0 md:text-3xl"
          >
            {t('Tech Blogs')}
          </Text>
        </div>
        <div className="mt-[32px] grid grid-flow-row grid-cols-1 gap-[32px] md:grid-cols-2 lg:grid-cols-3 lg:gap-[60px]">
          {blogs.map((post: any, index: number) => (
            <Article
              key={index}
              src={post.thumbnail}
              link={post.link}
              publishDate={post.pubDate}
              title={post.title}
            />
          ))}
        </div>
        <div className="mt-[42px] flex text-center md:text-left">
          <Button
            className="flex !w-full justify-center px-4 sm:px-8 lg:!w-auto"
            htmlType="link"
            type="secondary"
            newTab
            onClick={() => {
              window.open('https://co-creation.wesionary.team/', '_blank');
            }}
          >
            {t('Explore all articles on Note')} &nbsp;{' '}
            <ExternalLinkIcon className="flex h-5 w-5" />
          </Button>
        </div>
      </section>
    );
  }
);

export { TechBlog };
