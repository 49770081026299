module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    '../../packages/ui/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    config: {
      initialColorMode: 'light',
      useSystemColorMode: true,
    },
    extend: {
      screens: {
        '3xl': '1875px',
        break: { min: '1300px', max: '1875px' },
      },
      backgroundImage: {
        techBg: "url('/tech_bg.png')",
      },
      maxWidth: {
        medium: '45.625rem',
        desktop: '71.5rem',
        tablet: '67.75rem',
        xl: '90rem',
      },
      borderRadius: {
        'btn-rs': '27px',
      },
      padding: {
        'btn-y': '15px',
      },
      keyframes: {
        'fade-in': {
          '0%': {
            opacity: '0',
          },
          '100%': {
            opacity: '1',
          },
        },
      },
      animation: {
        'fade-in': 'fade-in 3s ease',
        'pulse-slow': 'pulse 8s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      },
      fontSize: {
        medium: '2rem',
      },
      lineHeight: {
        medium: '2.375rem',
        big: '3.625rem',
      },
      fontFamily: {
        Poppins: ['Poppins'],
        Rubik: ['Rubik'],
        Albert: ['Albert sans'],
      },
    },

    colors: {
      white: '#FFFFFF',
      transparent: 'rgba(0, 0, 0, 0)',
      primary: {
        100: '#EAF4FD',
        200: '#D8EBFB',
        300: '#87B5E7',
        400: '#6493D0',
        500: '#3767B1',
        600: '#284F98',
        700: '#1B3A7F',
        900: '#0A142F',
      },
      gray: {
        200: '#F7F9FC',
        300: '#EAF4FD',
        500: '#C5CEE0',
        600: '#8F9BB3',
        primary: '#222B45',
        secondary: '#50586C',
        900: '#192038',
      },
      black: {
        secondary: '#222B45',
      },
      red: {
        500: '#ef4444',
      },
      green: {
        500: '#22C55E',
      },
    },
  },
  plugins: [require('@tailwindcss/line-clamp')],
  corePlugins: {
    preflight: false,
  },
};
